/**
 * Removes trailing whitespace or specified characters from end of string.
 *
 * A replacement for lodash's _.trimEnd([string=''], [chars=whitespace])
 * @param str
 * @param chars
 * @returns
 */
export const trimEnd = (str: string, chars: string | undefined) =>
  !chars ? str.trimEnd() : str.replace(new RegExp("[" + chars + "]+$"), "");

/**
 * Removes trailing whitespace or specified characters from start of string.
 *
 * A replacement for lodash's _.trimStart([string=''], [chars=whitespace])
 * @param str
 * @param chars
 * @returns
 */
export const trimStart = (str: string, chars: string | undefined) =>
  !chars ? str.trim() : str.replace(new RegExp("^[" + chars + "]+"), "");

/**
 * Removes trailing whitespace or specified characters from start and end of string.
 *
 * A replacement for lodash's _.trim([string=''], [chars=whitespace])
 * @param str
 * @param chars
 * @returns
 */
export const trim = (str: string, chars: string | undefined) =>
  !chars ? str.trim() : trimStart(trimEnd(str, chars), chars);
