import { AbBreadcrumbs } from "@advocatebase/web-components";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { defaultHeaderImage } from "../consts";
import { formatDate } from "../helpers/dates";

const PageTitle = ({
  title,
  description,
  date,
  dateFormat = "publishedOn",
  headerImage,
}: {
  title?: string;
  description?: string;
  date?: Date;
  dateFormat?: "lastUpdated" | "publishedOn";
  headerImage?: string;
}) => {
  if (!title) return null;

  const datePrefix =
    dateFormat === "publishedOn" ? "Published" : "Last Updated";

  return (
    <Box sx={{ position: "relative" }}>
      <Helmet>
        <title>AdvocateBase - {title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundImage: `url("${headerImage ?? defaultHeaderImage}")`,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: (theme) => theme.palette.primary.light,
          opacity: 0.9,
        }}
      />
      <Box
        sx={{
          position: "relative",
          color: (theme) => theme.palette.primary.contrastText,
          width: "100%",
          minHeight: "300px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AbBreadcrumbs
            sx={{ mb: 2 }}
            homeCrumb="Home"
            dropFinalCrumb
            rootPath="/"
            color="inherit"
          />
          <Typography variant="h1" textAlign="center" color="inherit">
            {title}
          </Typography>
          {date && (
            <Typography variant="body1" color="inherit">
              {datePrefix} on {formatDate(date)}
            </Typography>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default PageTitle;
