import {
  AbButton,
  ILink,
  useIsMobile,
  useIsTablet,
} from "@advocatebase/web-components";
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { ASSETS_BASE_URL, defaultHeaderImage } from "../consts";
import FeaturesSection from "./FeaturesPage/FeaturesSection";
import PricingSection from "./PricingPage/PricingSection";

interface HomePageProps {
  primaryLink: ILink;
  secondaryLink: ILink;
}

const HomePage = ({ primaryLink, secondaryLink }: HomePageProps) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallHeight = useMediaQuery("(max-height:500px)");

  return (
    <Box>
      <Helmet>
        <title>AdvocateBase</title>
        <meta
          name="description"
          content="Build the foundation for your movement with AdvocateBase, a volunteer management app for non-profit organisations."
        />
      </Helmet>
      <Box
        aria-label="header"
        component="header"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: `url("${defaultHeaderImage}")`,
            backgroundSize: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: (theme) => theme.palette.primary.dark,
            opacity: 0.75,
          }}
        />
        <Container
          maxWidth="sm"
          sx={{
            position: "relative",
            ...(isMobile ? { py: 8 } : { py: 18 }),
            minHeight: "300px",
            maxHeight: "calc(100vh - 65px)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              // @ts-ignore
              variant="baseHeading"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                fontSize: "2rem",
                letterSpacing: "2px",
                mb: isSmallHeight ? 0 : 2,
                textAlign: "center",
                ...(isMobile && {
                  mb: 0,
                }),
              }}
            >
              Simplify,
              {!isMobile && " Strengthen,"}
            </Typography>
            {isMobile && (
              <Typography
                // @ts-ignore
                variant="baseHeading"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                  fontSize: "2.2rem",
                  letterSpacing: "2px",
                  mb: isSmallHeight ? 0 : "5px",
                  textAlign: "center",
                }}
              >
                Strengthen,
              </Typography>
            )}
            <Typography
              // @ts-ignore
              variant="baseHeading"
              sx={{
                fontSize: "6rem",
                fontStyle: "italic",
                letterSpacing: "5px",
                lineHeight: 1,
                color: (theme) => theme.palette.primary.contrastText,
                mb: isSmallHeight ? 3 : 6,
                textAlign: "center",
                ...(isMobile && {
                  fontSize: "4rem",
                  letterSpacing: "2px",
                }),
              }}
            >
              Succeed
            </Typography>
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              spacing={{ xxs: 2, sm: 4 }}
              sx={{
                mb: 1.5,
                width: "100%",
                ...(isMobile && {
                  alignItems: "center",
                }),
              }}
            >
              <AbButton
                fullWidth
                href={primaryLink.href}
                variant="contained"
                size="large"
                sx={{
                  fontWeight: "bold",
                  maxWidth: "260px",
                }}
                color="warning"
              >
                {primaryLink.label}
              </AbButton>
              <AbButton
                fullWidth
                href={secondaryLink.href}
                variant="outlined"
                size="large"
                sx={{
                  maxWidth: "260px",
                  backgroundColor: "white",
                  borderColor: (theme) => theme.palette.warning.main,
                  borderWidth: "3px",
                  fontWeight: "bold",
                  "&:hover": {
                    borderWidth: "3px",
                  },
                }}
                color="warning"
              >
                {secondaryLink.label}
              </AbButton>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box sx={{ background: (theme) => theme.palette.grey[300], py: 4 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: isMobile ? 2 : 4,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ alignItems: "center" }}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "75%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h1">Our Mission</Typography>
              <Typography variant="h4" paragraph sx={{ textAlign: "center" }}>
                Our mission is to amplify the influence of your non-profit by
                strengthening and streamlining your volunteer management for
                maximum impact.
              </Typography>
            </Box>
            <Box
              sx={{
                width: isMobile ? "100%" : isTablet ? "50%" : "500px",
                display: "flex",
                alignItems: "center",
                overflowX: "clip",
              }}
            >
              <Box
                component="img"
                src={`${ASSETS_BASE_URL}/advocatebase/screenshots/mobile_teams_map_3.jpg`}
                alt="AdvocateBase"
                sx={{
                  maxWidth: "100%",
                  padding: 0,
                  margin: 0,
                  ...(isTablet && !isMobile && { scale: "1.2" }),
                }}
              />
            </Box>
          </Stack>
        </Container>
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 4,
        }}
      >
        <Typography variant="h1">Features</Typography>
        <FeaturesSection buttonLink={primaryLink} />
      </Container>
      <Box sx={{ background: (theme) => theme.palette.grey[300], py: 4 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 0,
          }}
        >
          <Typography variant="h1">Pricing</Typography>
          <PricingSection buttonLink={primaryLink} />
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;
