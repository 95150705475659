import { logger } from "@advocatebase/toolbox";
import {
  AbContentRenderer,
  AbScrollTopFab,
  useIsLaptop,
} from "@advocatebase/web-components";
import { Box, Container } from "@mui/material";
import parse from "gray-matter";
import React from "react";
import { useLocation } from "react-router-dom";
import { trim } from "../helpers/strings";
import PageTitle from "../structure/PageTitle";
import NotFoundPage from "./NotFoundPage";

interface ContentState {
  status: "loading" | "error" | "success";
  markdown?: string;
}

const PageInner = ({ markdown }: { markdown: string }) => {
  const { data, content } = parse(markdown);
  const isLaptop = useIsLaptop();

  return (
    <Box>
      <PageTitle {...data} />
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: isLaptop ? 3 : 4,
          pb: isLaptop ? 6 : 8,
        }}
      >
        <AbContentRenderer content={content} sx={{ width: "100%" }} />
      </Container>
      <AbScrollTopFab />
    </Box>
  );
};

const ServerPage = () => {
  const location = useLocation();
  const [contentStatus, setContentStatus] = React.useState<ContentState>({
    status: "loading",
    markdown: "",
  });

  React.useEffect(() => {
    setContentStatus({ status: "loading" });
    // Fetch the Markdown content from the server based on the 'pageSlug' param
    fetch(`${process.env.PUBLIC_URL}/pages/${trim(location.pathname, "/")}.md`) // Adjust the API endpoint accordingly
      .then((response) => response.text())
      .then((data) => {
        if (data.toLowerCase().startsWith("<!doctype html>")) {
          throw Error("404 Not Found");
        }
        setContentStatus({ status: "success", markdown: data });
      })
      .catch((error) => {
        logger.debug(error);
        setContentStatus({ status: "error" });
      });
  }, [location]);

  switch (contentStatus.status) {
    case "error":
      return <NotFoundPage />;
    case "success":
      return <PageInner markdown={contentStatus.markdown ?? ""} />;
    case "loading":
    default:
      return null;
  }
};

export default ServerPage;
