import { AbDevNotice } from "@advocatebase/web-components";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import ServerPage from "./pages/ServerPage";
import { routesWithElements } from "./routeElements";
import CookieBanner from "./structure/CookieBanner";
import Footer from "./structure/Footer";
import Header from "./structure/Header";

const App = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <AbDevNotice />
      <CookieBanner />
      <Header
        homePageSlug="/"
        primaryLink={{ label: "Contact Sales", href: "/contact" }}
        secondaryLink={{ label: "Login", href: "https://app.advocatebase.com" }}
        navLinks={[
          {
            label: "Features",
            sublinks: [
              { label: "App Features", href: "/features" },
              { label: "Integrations", href: "/integrations" },
            ],
          },
          { label: "Pricing", href: "/pricing" },
          { label: "Resources", href: "/resources" },
        ]}
      />
      <Box
        component="main"
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
      >
        <Routes>
          {routesWithElements.map((route) => (
            <Route path={route.path} element={route.element} key={route.path} />
          ))}
          <Route path="*" element={<ServerPage />} />
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
};

export default App;
