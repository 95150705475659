export default function initAnalytics(measurementId) {
  // Prevent this from running if either there is no measurementId or if this has already been run
  if (
    measurementId &&
    !document.querySelector(`script[src*="${measurementId}"]`)
  ) {
    // Load Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.onload = () => {
      // Initalise Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", measurementId);
    };
    document.head.appendChild(script);
  }
}
