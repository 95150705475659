import { ILink } from "@advocatebase/web-components";
import { Box, Container } from "@mui/material";
import PageTitle from "../../structure/PageTitle";
import FeaturesSection from "./FeaturesSection";

const FeaturesPage = ({ buttonLink }: { buttonLink: ILink }) => {
  return (
    <Box>
      <PageTitle
        title="Features"
        description="The key features you need to streamline and strengthen your organisation."
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 4,
        }}
      >
        <FeaturesSection buttonLink={buttonLink} />
      </Container>
    </Box>
  );
};

export default FeaturesPage;
