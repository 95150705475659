import {
  AbButton,
  AbLink,
  ILink,
  useIsTablet,
} from "@advocatebase/web-components";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Container,
  IconButton,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import {
  bindHover,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React from "react";
import { ASSETS_BASE_URL, HEADER_HEIGHT } from "../consts";
import AbDrawer from "./AbDrawer";
import { NavLink, ParentILink } from "./types";

const closedStyles: SystemStyleObject<Theme> = {
  display: "flex",
  alignItems: "center",
  px: 2,
  border: 0,
  borderTop: 3,
  borderBottom: 3,
  borderColor: "transparent",
  borderStyle: "solid",
  cursor: "pointer",
};
const hoverStyles: SystemStyleObject<Theme> = {
  borderBottomColor: (theme) => theme.palette.primary.main,
  backgroundColor: (theme) => theme.palette.grey[100],
};

const LinkItem = ({ link }: { link: ILink }) => {
  return (
    <AbLink
      href={link.href}
      // @ts-ignore
      variant="h4"
      // @ts-ignore
      data-basic
      sx={{
        color: "inherit",
        textDecoration: "none",
        height: "auto",
        ...closedStyles,
        "&:hover": hoverStyles,
      }}
    >
      {link.label}
    </AbLink>
  );
};

const DropdownItem = ({ link }: { link: ParentILink }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  return (
    <Box sx={{ height: "auto" }}>
      <Box
        {...bindHover(popupState)}
        sx={{
          height: "100%",
          ...closedStyles,
          "&:hover": hoverStyles,
          ...(popupState.isOpen && hoverStyles),
        }}
      >
        <Typography variant="h4" key={link.label}>
          {link.label}
        </Typography>
      </Box>
      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper sx={{ py: 2, px: 3 }} variant="outlined">
          {link.sublinks.map((sublink, i) => (
            <Typography
              variant="h4"
              sx={{ mb: i < link.sublinks.length - 1 ? 2 : 0 }}
              key={sublink.label}
            >
              <AbLink
                href={sublink.href}
                onClick={() => void popupState.close()}
              >
                {sublink.label}
              </AbLink>
            </Typography>
          ))}
        </Paper>
      </HoverPopover>
    </Box>
  );
};

const Header = ({
  homePageSlug = "/",
  navLinks,
  primaryLink,
  secondaryLink,
}: {
  homePageSlug: string;
  navLinks: NavLink[];
  primaryLink?: ILink;
  secondaryLink?: ILink;
}) => {
  const isTablet = useIsTablet();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <>
      <AbDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        navLinks={navLinks}
        primaryLink={primaryLink}
        secondaryLink={secondaryLink}
        homePageSlug={homePageSlug}
      />
      <Paper
        component="header"
        square
        sx={{
          color: (theme) => theme.palette.text.primary,
          backgroundColor: (theme) => theme.palette.background.default,
          width: "100%",
          position: "sticky",
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
          height: HEADER_HEIGHT,
        }}
      >
        <Container
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              height: "100%",
            }}
          >
            <AbLink
              // @ts-ignore
              data-basic
              href={homePageSlug}
              sx={{
                border: "none",
                display: "flex",
                alignItems: "center",
                maxWidth: "100%",
                mr: 2,
                ...(isTablet && { mr: 0.5 }),
              }}
            >
              <img
                src={`${ASSETS_BASE_URL}/advocatebase/logos/secondaryLogoGreen.svg`}
                height={isTablet ? "38px" : "40px"}
                alt="AdvocateBase Logo"
                style={{
                  maxWidth: "100%",
                  marginTop: "-5px",
                  ...(isTablet && {
                    marginTop: "3px",
                    marginLeft: "-6px",
                  }),
                }}
              />
            </AbLink>
            {!isTablet &&
              navLinks.map((link) =>
                "sublinks" in link ? (
                  <DropdownItem link={link} key={link.label} />
                ) : (
                  <LinkItem link={link} key={link.label} />
                )
              )}
          </Box>
          {primaryLink && !isTablet && (
            <AbButton
              size="medium"
              href={primaryLink.href}
              // @ts-ignore
              variant="contained"
              sx={{ py: "7px" }}
            >
              {primaryLink.label}
            </AbButton>
          )}
          {secondaryLink && !isTablet && (
            <AbButton
              size="medium"
              href={secondaryLink.href}
              // @ts-ignore
              variant="outlined"
              sx={{ ml: 2, py: "6px" }}
            >
              {secondaryLink.label}
            </AbButton>
          )}
          {isTablet && (
            <Box sx={{ mr: -1 }}>
              <IconButton
                size="medium"
                edge="start"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontSize: 32,
                }}
                aria-label="open drawer"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            </Box>
          )}
        </Container>
      </Paper>
    </>
  );
};

export default Header;
