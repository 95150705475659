import { AbButton, ILink, useIsMobile } from "@advocatebase/web-components";
import { Box, Container, Paper, Typography } from "@mui/material";
import { ASSETS_BASE_URL } from "../consts";
import PageTitle from "../structure/PageTitle";

export const getLogo = (name: string, variant: "tall" | "wide" | "round") =>
  `${ASSETS_BASE_URL}/integrations/${name.toLowerCase()}-${variant}.svg`;

interface IntegrationDetails {
  name: string;
  label: string;
  description: string;
  href: string;
}

const integrationsList: IntegrationDetails[] = [
  {
    name: "nationbuilder",
    label: "Nationbuilder",
    description:
      "Integrate your Nationbuilder account with AdvocateBase to supercharge your volunteer coordination, teams, training and event tracking. Ditch spreadsheets, banish admin and strengthen your volunteers and teams with AdvocateBase.",
    href: "/resources/integrating_advocatebase_with_nationbuilder",
  },
];

const IntegrationsPage = ({ buttonLink }: { buttonLink: ILink }) => {
  const isMobile = useIsMobile();

  return (
    <Box>
      <PageTitle
        title="Integrations"
        description="Reduce admin work and connect your systems together using our integrations."
      />
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 4,
        }}
      >
        <Typography variant="h4" paragraph>
          Reduce admin work and connect your systems together using our
          integrations.
        </Typography>
        {integrationsList.map((integ) => (
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            {isMobile ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    mb: 2,
                    alignItems: "center",
                  }}
                >
                  <img
                    width="15%"
                    src={getLogo(integ.name, "round")}
                    alt={`${integ.name} logo`}
                  />
                  <Typography variant="h3" sx={{ ml: 2 }}>
                    {integ.label}
                  </Typography>
                </Box>
                <Typography variant="body1">{integ.description}</Typography>
              </>
            ) : (
              <Box sx={{ display: "flex" }}>
                <img
                  width="10%"
                  src={getLogo(integ.name, "round")}
                  alt={`${integ.name} logo`}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "justify",
                    ml: 2,
                  }}
                >
                  <Typography variant="h3" sx={{ mb: 1 }}>
                    {integ.label}
                  </Typography>
                  <Typography variant="body1">{integ.description}</Typography>
                </Box>
              </Box>
            )}
            <AbButton
              href={integ.href}
              variant="contained"
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              Learn More
            </AbButton>
          </Paper>
        ))}
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
          <Typography variant="h3" paragraph>
            Something Else?
          </Typography>
          <Typography variant="body1" paragraph>
            Don't see an integration that you need here? Contact us to discuss
            if we can create the integration you need.
          </Typography>
          <AbButton
            href={buttonLink.href}
            variant="contained"
            sx={{
              alignSelf: "start",
            }}
          >
            {buttonLink.label}
          </AbButton>
        </Paper>
      </Container>
    </Box>
  );
};

export default IntegrationsPage;
