import { AbButton, ILink } from "@advocatebase/web-components";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

interface IFeature {
  text: string;
  icon: React.ReactElement;
}

const PaymentPlanCard = ({
  title,
  subtitle,
  features,
  // starred = false,
  buttonLink,
}: {
  title: string;
  subtitle?: string;
  features: IFeature[];
  // starred?: boolean;
  buttonLink?: ILink;
}) => {
  return (
    <Container maxWidth="xs" disableGutters>
      {/* Note - remove the "height: fit-content" css to make all cards in a stack the same height. */}
      <Card sx={{ height: "fit-content" }}>
        <CardHeader
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.main,
          }}
          title={
            <Typography
              variant="h3"
              sx={{
                color: (theme) => theme.palette.secondary.contrastText,
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          }
          subheader={
            subtitle && (
              <Typography
                variant="h4"
                sx={{
                  fontStyle: "italic",
                  mt: 0.5,
                  color: (theme) => theme.palette.secondary.contrastText,
                  textAlign: "center",
                }}
              >
                {subtitle}
              </Typography>
            )
          }
          // action={
          //   starred && (
          //     <StarOutlineIcon
          //       sx={{ color: (theme) => theme.palette.primary.contrastText }}
          //     />
          //   )
          // }
          disableTypography
        ></CardHeader>
        <CardContent>
          <List sx={{ py: 0 }}>
            {features.map((feature) => (
              <ListItem key={feature.text}>
                <ListItemIcon>{feature.icon}</ListItemIcon>
                <ListItemText primary={feature.text} />
              </ListItem>
            ))}
          </List>
        </CardContent>
        {buttonLink && (
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "center",
              px: 2,
              pb: 2,
              pt: 0,
            }}
          >
            <AbButton href={buttonLink.href} variant="contained" sx={{ mb: 1 }}>
              {buttonLink.label}
            </AbButton>
          </CardActions>
        )}
      </Card>
    </Container>
  );
};

export default PaymentPlanCard;
