import { ILink } from "@advocatebase/web-components";
import { Box, Container } from "@mui/material";
import PageTitle from "../../structure/PageTitle";
import PricingSection from "./PricingSection";

const PricingPage = ({ buttonLink }: { buttonLink: ILink }) => {
  return (
    <Box>
      <PageTitle
        title="Pricing"
        description="Whether you are just getting started or seeking to grow, we have customisable plans that are right for you."
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 4,
        }}
      >
        <PricingSection buttonLink={buttonLink} />
      </Container>
    </Box>
  );
};

export default PricingPage;
