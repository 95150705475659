import { useAbNavigator } from "@advocatebase/web-components";
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import getMarkdownMetadata, {
  MarkdownMetadata,
} from "../helpers/getMarkdownMetadata";
import PageTitle from "../structure/PageTitle";

const ResourcesPage = () => {
  const nav = useAbNavigator();
  const [resources, setResources] = React.useState<MarkdownMetadata[]>([]);
  React.useEffect(() => {
    (async () => {
      setResources((await getMarkdownMetadata("resources")) ?? []);
    })();
  }, []);

  return (
    <Box>
      <PageTitle
        title="Resources"
        description="Welcome to our resources hub. Here you will find blogs, help guides, frequently asked questions and case studies."
      />
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 4,
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Welcome to our resources hub. Here you will find blogs, help guides,
          frequently asked questions and case studies.
        </Typography>
        <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
          {resources.map((resource) => (
            <Grid item xs={4} key={resource.relativePath}>
              <Card sx={{ width: "100%" }}>
                <CardActionArea onClick={() => nav.to(resource.relativePath)}>
                  <Box
                    sx={{
                      width: "100%",
                      paddingTop: "56.25%", // 1600:900 image ratio
                      position: "relative",
                    }}
                  >
                    <CardMedia
                      image={resource.featuredImage}
                      title={resource.title}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <CardHeader
                    title={
                      <Typography variant="body1" paragraph sx={{ mb: 0.5 }}>
                        {resource.title}
                      </Typography>
                    }
                    subheader={
                      <Typography variant="body1" sx={{ opacity: 0.6 }}>
                        {resource.description}
                      </Typography>
                    }
                    disableTypography
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ResourcesPage;
