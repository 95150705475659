import { assertCustom, logger } from "@advocatebase/toolbox";
import Joi from "joi";
import _ from "lodash";

export interface MarkdownMetadata {
  relativePath: string;
  [key: string]: string;
}

function assertValidMetadata(data: any): asserts data is MarkdownMetadata[] {
  assertCustom(
    data,
    () => {
      const schema = Joi.array().items(
        Joi.object({ relativePath: Joi.string() }).pattern(
          Joi.string(),
          Joi.string()
        )
      );
      const validationResult = schema.validate(data);
      return !validationResult.error;
    },
    "Markdown metadata is invalid."
  );
}

export default async function getMarkdownMetadata(
  path: string
): Promise<MarkdownMetadata[] | null> {
  try {
    const response = await fetch(
      `${process.env.PUBLIC_URL}/pages/${_.trim(path, "/")}/metadata.json`
    );
    const data = await response.text();
    if (data.toLowerCase().startsWith("<!doctype html>")) {
      throw Error("404 Not Found");
    }
    const metadata = JSON.parse(data);
    assertValidMetadata(metadata);
    return metadata;
  } catch (e) {
    logger.debug(e);
    return null;
  }
}
