export enum RouteId {
  Homepage,
  Features,
  Contact,
  Pricing,
  Integrations,
  Resources,
}

interface RouteMetaData {
  path: string;
}

// Note: route elements are separated out from route meta data so that a site-map can be correctly built.
export const routeMetaData: Record<RouteId, RouteMetaData> = {
  [RouteId.Homepage]: {
    path: "/",
  },
  [RouteId.Features]: {
    path: "/features",
  },
  [RouteId.Contact]: {
    path: "/contact",
  },
  [RouteId.Pricing]: {
    path: "/pricing",
  },
  [RouteId.Integrations]: {
    path: "/integrations",
  },
  [RouteId.Resources]: {
    path: "/resources",
  },
};
