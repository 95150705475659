import { AbLink } from "@advocatebase/web-components";
import { Box, Typography, Container } from "@mui/material";

const legalLinks = [
  { label: "Features", href: "/features" },
  { label: "Pricing", href: "/pricing" },
  { label: "Contact Sales", href: "/contact" },
  {
    label: "Privacy Policy",
    href: "/legal/privacy-policy",
  },
];

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        textAlign: "center",
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
        width: "100%",
      }}
    >
      <Container>
        <Typography variant="body1" paragraph sx={{ my: 4, color: "inherit" }}>
          &#169; AdvocateBase 2023
        </Typography>
        <Typography variant="body2" paragraph sx={{ my: 4, color: "inherit" }}>
          {legalLinks.flatMap((link, i) => [
            <AbLink
              href={link.href}
              sx={{ color: "inherit" }}
              key={`${link.label}-1`}
            >
              {link.label}
            </AbLink>,
            i < legalLinks.length - 1 ? (
              <span key={`${link.label}-2`}>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </span>
            ) : null,
          ])}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
