import { AbButton, AbLink } from "@advocatebase/web-components";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import initAnalytics from "./initAnalytics";

const COOKIE_KEY = "cookiesConsent";
const COOKIE_LIFESPAN_DAYS = Number(process.env.REACT_APP_COOKIE_LIFESPAN_DAYS);
const OPTIONAL_COOKIES_REJECTED = "rejected";
const OPTIONAL_COOKIES_ACCEPTED = "accepted";
const GOOGLE_ANALYTICS_MEASUREMENT_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

// Function to set a cookie with an expiration date
const setCookie = (name: string, value: string, days: number) => {
  const expirationDate = DateTime.now().plus({ days });
  const cookie = `${name}=${value}; expires=${expirationDate.toHTTP()}; path=/`;
  document.cookie = cookie;
};

// Function to get the value of a cookie by name
const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName.trim() === name) {
      return cookieValue;
    }
  }
  return null;
};

const CookieBanner = () => {
  const [show, setShow] = React.useState(false);

  const acceptOptionalCookies = () => {
    setCookie(COOKIE_KEY, OPTIONAL_COOKIES_ACCEPTED, COOKIE_LIFESPAN_DAYS);
    initAnalytics(GOOGLE_ANALYTICS_MEASUREMENT_ID);
    setShow(false);
  };

  const rejectOptionalCookies = () => {
    setCookie(COOKIE_KEY, OPTIONAL_COOKIES_REJECTED, COOKIE_LIFESPAN_DAYS);
    setShow(false);
  };

  React.useEffect(() => {
    // If the user has neither accepted nor rejected cookies yet
    const cookiesConsent = getCookie(COOKIE_KEY);
    if (!cookiesConsent) {
      setShow(true);
    } else if (cookiesConsent === OPTIONAL_COOKIES_ACCEPTED) {
      initAnalytics(GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }
  }, []);

  if (!show) return null;
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        borderTop: "2px solid",
        borderRadius: 0,
        borderColor: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.appBar + 2,
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <Container maxWidth="xl" sx={{ my: 3 }}>
        <Stack spacing={2} direction={{ md: "row", sm: "column" }}>
          <Box sx={{ my: 1 }}>
            <Typography variant="h2" sx={{ mb: 1 }}>
              Cookies Notice
            </Typography>
            <Typography variant="body1">
              We use cookies to enhance your experience and collect anonymous
              statistics to improve our website. For details, read our{" "}
              <AbLink href="/legal/privacy-policy">Privacy Policy</AbLink>.
              Essential cookies are always enabled, however you can accept or
              reject optional cookies here.
            </Typography>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            sx={{ alignItems: "center", my: 1 }}
          >
            <AbButton
              size="medium"
              onClick={acceptOptionalCookies}
              variant="contained"
            >
              Accept
            </AbButton>
            <AbButton
              size="medium"
              onClick={rejectOptionalCookies}
              variant="outlined"
            >
              Reject
            </AbButton>
          </Stack>
        </Stack>
      </Container>
    </Paper>
  );
};

export default CookieBanner;
