import { ILink } from "@advocatebase/web-components";
import _ from "lodash";
import ContactPage from "./pages/ContactPage";
import FeaturesPage from "./pages/FeaturesPage";
import HomePage from "./pages/Homepage";
import IntegrationsPage from "./pages/IntegrationsPage";
import PricingPage from "./pages/PricingPage";
import ResourcesPage from "./pages/ResourcesPage";
import { RouteId, routeMetaData } from "./routeMetaData";

const LINK_CONTACT_SALES: ILink = {
  label: "Contact Sales",
  href: "/contact",
};

const LINK_LOGIN: ILink = {
  label: "Login",
  href: "https://app.advocatebase.com",
};

interface RouteElement {
  element: JSX.Element;
}

// Note: route elements are separated out from route meta data so that a site-map can be correctly built.
export const routeElements: Record<RouteId, RouteElement> = {
  [RouteId.Homepage]: {
    element: (
      <HomePage primaryLink={LINK_CONTACT_SALES} secondaryLink={LINK_LOGIN} />
    ),
  },
  [RouteId.Features]: {
    element: <FeaturesPage buttonLink={LINK_CONTACT_SALES} />,
  },
  [RouteId.Contact]: {
    element: <ContactPage />,
  },
  [RouteId.Pricing]: {
    element: <PricingPage buttonLink={LINK_CONTACT_SALES} />,
  },
  [RouteId.Integrations]: {
    element: <IntegrationsPage buttonLink={LINK_CONTACT_SALES} />,
  },
  [RouteId.Resources]: {
    element: <ResourcesPage />,
  },
};

export const routesWithElements = Object.values(RouteId)
  .filter(_.negate(_.isString))
  .map((routeId) => ({
    ...routeMetaData[routeId as RouteId],
    ...routeElements[routeId as RouteId],
  }));
