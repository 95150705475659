import { ILink } from "@advocatebase/web-components";
import CommentIcon from "@mui/icons-material/Comment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import GroupsIcon from "@mui/icons-material/Groups";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SecurityIcon from "@mui/icons-material/Security";
import ShareIcon from "@mui/icons-material/Share";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Stack, Typography } from "@mui/material";
import PaymentPlanCard from "./PaymentPlanCard";

const FeaturesSection = ({ buttonLink }: { buttonLink: ILink }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h4">
        The key features you need to streamline and strengthen your
        organisation.
      </Typography>
      <Stack
        spacing={4}
        direction={{ xs: "column", sm: "row" }}
        sx={{ mt: 2, mb: 1 }}
      >
        <PaymentPlanCard
          title="Coordinate Plan"
          buttonLink={buttonLink}
          features={[
            { text: "Track volunteers and events", icon: <PersonIcon /> },
            { text: "Organise volunteers into teams", icon: <GroupsIcon /> },
            {
              text: "Visualise your data with maps and graphs",
              icon: <LocationOnIcon />,
            },
            {
              text: "Assign resources and training sessions",
              icon: <NoteAddIcon />,
            },
            {
              text: "Share key info across your organisation in real-time",
              icon: <ShareIcon />,
            },
            {
              text: "Secure and reliable",
              icon: <SecurityIcon />,
            },
          ]}
        />
        <PaymentPlanCard
          title="Activate Plan"
          subtitle="Coming soon"
          features={[
            {
              text: "On-board and train your volunteers",
              icon: <PersonAddIcon />,
            },
            { text: "Create custom training modules", icon: <YouTubeIcon /> },
            { text: "Automate reference checks", icon: <CommentIcon /> },
            {
              text: "Enable volunteers to RSVP to events",
              icon: <EventAvailableIcon />,
            },
            {
              text: "Automatically remind volunteers of upcoming events",
              icon: <NotificationsIcon />,
            },
          ]}
        />
      </Stack>
    </Box>
  );
};

export default FeaturesSection;
