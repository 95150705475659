import { AbButton, ILink } from "@advocatebase/web-components";
import { Box, Container, Typography } from "@mui/material";

const PricingSection = ({ buttonLink }: { buttonLink: ILink }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" textAlign="center">
          Whether you are just getting started or seeking to grow your
          organisation, we have customisable plans that are right for you.
        </Typography>
      </Container>
      <AbButton
        href={buttonLink.href}
        variant="contained"
        sx={{
          mt: 2,
          mb: 1,
        }}
      >
        {buttonLink.label}
      </AbButton>
    </Box>
  );
};

export default PricingSection;
