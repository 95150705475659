import { DateTime } from "luxon";

export function formatDate(date: Date) {
  const luxonDate = DateTime.fromJSDate(date);

  const day = luxonDate.day;
  const month = luxonDate.monthShort;
  const year = luxonDate.year;

  let daySuffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  }

  return `${day}${daySuffix} ${month} ${year}`;
}
