import { AbButton } from "@advocatebase/web-components";
import { Box, Container, Typography } from "@mui/material";
import PageTitle from "../structure/PageTitle";

// Example of a good pricing page: https://bareynol.github.io/mui-theme-creator/#
// Example of a good homepage: https://slack.com/intl/en-gb/

const ContactPage = () => {
  // const editFormRef = React.useRef();

  return (
    <Box>
      <PageTitle
        title="Contact Sales"
        description="Contact our team to see a demo of our AdvocateBase app and talk with a customer service specialist."
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 4,
        }}
        maxWidth="sm">
        {/* <Typography variant="h4" sx={{ textAlign: "justify" }}>
          If you are interested in learning more about AdvocateBase and seeing a
          demo of the app, please contact our sales team via the form below. One
          of our customer service specialists will be in touch.
        </Typography> */}
        <Typography variant="h4" sx={{ textAlign: "justify" }}>
          If you are interested in learning more about AdvocateBase and seeing a
          demo of the app, please contact our sales team on{" "}
          <b>hello@advocatebase.com</b>. We look forward to talking with you.
        </Typography>
        <Box sx={{ mt: 2 }}></Box>

        <AbButton href="mailto:hello@advocatebase.com" variant="contained">
          Get in Touch
        </AbButton>

        {/* <EditForm
          sx={{ mt: 2, mb: 1 }}
          fields={[
            {
              id: "name",
              label: "Your Name*",
              type: "stack",
              stack: [
                {
                  id: "first",
                  label: "First name",
                  validators: "required",
                },
                {
                  id: "last",
                  label: "Surname",
                  validators: "required",
                },
              ],
            },
            {
              id: "email",
              label: "Email address",
              validators: "required",
            },
            {
              id: "phone",
              label: "Phone number",
              validators: "required",
            },
            {
              id: "organisation",
              label: "Organisation",
              validators: "required",
            },
            {
              id: "role",
              label: "Your role",
              validators: "required",
            },
            {
              id: "organisationSize",
              label: "Organisation size (number of volunteers)",
              type: "select-edit",
              options: [
                { id: 1, label: "1—20" },
                { id: 2, label: "21—50" },
                { id: 3, label: "51—150" },
                { id: 4, label: "151—500" },
                { id: 5, label: "500+" },
              ],
              validators: "required",
            },
            {
              id: "country",
              label: "Country",
              validators: "required",
            },
            {
              id: "message",
              label: "Message",
              multiline: true,
            },
          ]}
          data={{
            name: { first: "", last: "" },
            email: null,
            phone: null,
            organisation: null,
            role: null,
            organisationSize: null,
            country: null,
            message: null,
          }}
          ref={editFormRef}
        />
        <AbButton
          onClick={async () => {
            try {
              const submittedForm = await editFormRef.current.submit();

              const emailTo = "sales@advocatebase.com";
              const emailSub = "AdvocateBase enquiry";
              const emailBody = JSON.stringify(submittedForm);
              window.open(
                `mailto:${emailTo}?subject=${emailSub}&body=${emailBody}`,
                "_blank"
              );
            } catch {}
          }}
          variant="contained"
          sx={{
            mb: 1,
          }}
        >
          Submit
        </AbButton> */}
        {/* <Box sx={{ mt: 2 }}>
          <iframe
            title="Contact Form"
            style={{ border: "none", width: "100%" }}
            height="860px"
            src="https://noteforms.com/forms/demo-form-n0m6i3"
          ></iframe>
        </Box> */}
      </Container>
    </Box>
  );
};

export default ContactPage;
