import {
  AbButton,
  AbDivider,
  AbLink,
  ILink,
  useAbNavigator,
} from "@advocatebase/web-components";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from "@mui/material";
import React from "react";
import { ASSETS_BASE_URL, HEADER_HEIGHT } from "../consts";
import { NavLink } from "./types";

// const iOS =
//   typeof navigator !== "undefined" &&
//   /iPad|iPhone|iPod/.test(navigator.userAgent);

interface AbDrawerProps
  extends Omit<SwipeableDrawerProps, "onClose" | "onOpen"> {
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  navLinks: NavLink[];
  primaryLink?: ILink;
  secondaryLink?: ILink;
  homePageSlug: string;
}

const AbDrawer = ({
  open,
  onClose = () => {},
  onOpen = () => {},
  homePageSlug,
  navLinks,
  primaryLink,
  secondaryLink,
  children,
}: AbDrawerProps) => {
  const nav = useAbNavigator();

  const [openArray, setOpenArray] = React.useState<boolean[]>(
    Array(navLinks.length).fill(false)
  );

  const handleClick = (index: number) => {
    setOpenArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };

  React.useEffect(() => {
    setOpenArray(Array(navLinks.length).fill(false));
  }, [open, navLinks.length]);

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableBackdropTransition
      disableDiscovery
      disableSwipeToOpen
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 1,
        width: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "100%",
          boxSizing: "border-box",
          backgroundColor: (theme) => theme.palette.background.default,
        },
      }}
    >
      <Container
        sx={{
          height: HEADER_HEIGHT,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        aria-label="toolbar"
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <AbLink
            // @ts-ignore
            data-basic
            href={homePageSlug}
            onClick={onClose}
            sx={{
              border: "none",
              display: "flex",
              maxWidth: "100%",
              mr: 0.5,
            }}
          >
            <img
              src={`${ASSETS_BASE_URL}/advocatebase/logos/secondaryLogoGreen.svg`}
              alt="AdvocateBase Logo"
              height="38px"
              style={{
                maxWidth: "100%",
                marginTop: "-2px",
                marginLeft: "-6px",
              }}
            />
          </AbLink>
        </Box>
        <Box sx={{ mr: -1 }}>
          <IconButton
            size="medium"
            edge="start"
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: 32,
            }}
            aria-label="close drawer"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Container>
      <AbDivider orientation="horizontal" />
      <Box
        aria-label="content"
        sx={{
          width: "100%",
          px: 2,
          pb: 2,
          pt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List disablePadding>
          {navLinks.map((link, i) =>
            "sublinks" in link ? (
              <Box key={link.label}>
                <ListItem disablePadding>
                  <Paper
                    variant="outlined"
                    sx={{
                      mb: 2,
                      width: "100%",
                      backgroundColor: (theme) => theme.palette.grey[200],
                    }}
                  >
                    <ListItemButton onClick={() => handleClick(i)}>
                      <ListItemText
                        primary={
                          <Typography variant="h4">{link.label}</Typography>
                        }
                        disableTypography
                      />
                      {openArray[i] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                  </Paper>
                </ListItem>
                <Collapse in={openArray[i]} timeout="auto" unmountOnExit>
                  <List disablePadding sx={{ mb: 1, mt: -1 }}>
                    {link.sublinks.map((sublink) => (
                      <ListItem key={sublink.label} disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => {
                            nav.toLink(sublink);
                            onClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography variant="h4">
                                {sublink.label}
                              </Typography>
                            }
                            disableTypography
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Box>
            ) : (
              <ListItem key={link.label} disablePadding>
                <Paper
                  variant="outlined"
                  sx={{
                    mb: 2,
                    width: "100%",
                    backgroundColor: (theme) => theme.palette.grey[200],
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      nav.toLink(link);
                      onClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h4">{link.label}</Typography>
                      }
                      disableTypography
                    />
                  </ListItemButton>
                </Paper>
              </ListItem>
            )
          )}
        </List>
        {primaryLink && (
          <AbButton
            size="medium"
            href={primaryLink.href}
            onClick={() => void onClose()}
            // @ts-ignore
            variant="contained"
            sx={{ mt: 1, fontSize: "1.2rem" }}
          >
            {primaryLink.label}
          </AbButton>
        )}
        {secondaryLink && (
          <AbButton
            size="medium"
            href={secondaryLink.href}
            onClick={() => void onClose()}
            // @ts-ignore
            variant="outlined"
            sx={{ mt: 2, fontSize: "1.2rem" }}
          >
            {secondaryLink.label}
          </AbButton>
        )}
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default AbDrawer;
